












































































































































































































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import {mapActions, mapGetters, mapState} from "vuex";

import ProposalForm from "@/components/ProposalForm.vue";
import ProposalFormDocument from "@/components/ProposalFormDocument.vue";
import ItemDocument from "@/components/ItemDocument.vue";
import FormBox from "@/components/UI/form/FormBox.vue";
import Button from "@/components/UI/Button.vue";
import Checkbox from "@/components/UI/form/Checkbox.vue";
import IconBase from "@/components/UI/IconBase.vue";
import Cert from "@/components/UI/icon/Cert.vue";
import NotCert from "@/components/UI/icon/NotCert.vue";
import Products from "@/components/Products.vue";
import {Tab, Tabs} from "@/components/Tabs";
import {InitialFileTypes} from '@/constants/InitialFileTypes';
import {IDocInfo} from '@/store/modules/application/application.interfaces';
import InputSwitch from "@/components/UI/form/InputSwitch.vue";
import {UpdRequestStatuses} from '@/constants/UpdRequestStatuses';
import ModalWithField from "@/components/ModalWithField.vue";
import ModalWithFields from "@/components/ModalWithFields.vue";

import { IApplicationState } from "@/store/modules/application/application.interfaces";
import { SKZI_STATE_IDS } from "@/store/modules/application/application.constants";

@Component({
  computed: {
    ...mapState("application", [
      "isDocumentDownload",
      "activeTab",
      "isDocumentsCollected",
      "isCertificateRequested",
      "certificate",
      "statusId",
      "isEdit",
      "isUPDrequested",
      "isSendUPDtoEDM",
      "edmTypes",
      "hideUtd",
      "updStatusId",
      "updStatus",
      "updocument",
      "isFNSIssueAvailable",
      "filialClientFlags",
      "privateKeyExportPolicy",
      "isPresetContainer",
      "isPdnUsingProhibited",
      "skziState",
    ]),
    ...mapState("applicationListState", ["isArchive"]),
    ...mapGetters("application", [
      "getCollectedDocuments",
      "getDocumentsToUpload",
      "getAnotherDocuments",
      "getApplicationInfo",
      "getIsSystemProcessing",
      "getIsFormValid",
      "getDownloadDocuments",
      "getApplicationStatuses",
      "getTypeEntreValue",
      "getUploadTabDescription",
      "getSkziStatus",
      "needSignCertRequest",
    ]),
    ...mapGetters("productsState", ["getHasTariffFNS"]),
    ...mapGetters("authorizationState", ["isManagerPartner"])
  },
  methods: {
    ...mapActions("application", [
      "setActiveTab",
      "genRequestFile",
      "signCertRequest",
      "downloadHandler",
      "downloadRaw",
      "install",
      "toggleSendUPDtoEDM",
      "requestUPD",
      "handleUploadReq",
      "changePrivateKeyExportPolicy",
      "clearSMEVChecks",
      "setEdmTypeId",
      "fetchAnotherUpload",
      "getLicensePdf",
    ])
  },
  components: {
    ProposalForm,
    ProposalFormDocument,
    Products,
    Button,
    Checkbox,
    ItemDocument,
    IconBase,
    Cert,
    NotCert,
    Tabs,
    Tab,
    InputSwitch,
    ModalWithField,
    ModalWithFields
  }
})
export default class FormRequest extends Vue {
  /* data */

  /* computed */
  public hideUtd!: boolean;
  public statusId!: number;
  public filesDownload!: [];
  public certificate!: IDocInfo[];
  public isUPDrequested!: boolean;
  public isSendUPDtoEDM!: boolean;
  public getHasTariffFNS!: boolean;
  public isPresetContainer!: boolean;
  public getApplicationStatuses!: any;
  public skziState!: IApplicationState['skziState'];
  public getSkziStatus!: string;
  public needSignCertRequest!: boolean;

  private static get getInitialFileTypes() {
    return InitialFileTypes;
  }

  private get getUPDRequestStatuses() {
    return UpdRequestStatuses;
  }

  private get getLicenses() {
    return this.certificate.filter(c => c.licenseType != null);
  }

  private get skziLicense() {
    if (!this.skziState) return {
      shown: false,
      ready: false,
      id: null,
      licenseName: 'unknown',
    };

    return {
      shown: this.skziState.state in SKZI_STATE_IDS &&
        this.skziState.state !== SKZI_STATE_IDS.HasNotUnlimitedLicenseProduct,
      ready: this.skziState.state === SKZI_STATE_IDS.LicenseReady,
      id: this.skziState.license?.id ?? null,
      licenseName: this.skziState.license?.licenseName || this.skziState.license?.productName || 'unknown',
    };
  }
  
  /* methods */
  private requestUPD!: () => void;
  private genRequestFile!: (container?: {name: string; password: string}) => void;
  private clearSMEVChecks!: () => void;
  private handleUploadReq!: (evt: any) => void;
  private install!: (certificate: string) => void;
  private downloadRaw!: (docInfo: IDocInfo) => string;
  private downloadHandler!: (certificate: IDocInfo) => void;  
  private setEdmTypeId!: (edmTypeId: number | null) => void;
  private fetchAnotherUpload!: ({name, fileId}: {name: string; fileId?: number}) => void;

  private isProductInOrder(docInfos: IDocInfo[], type: InitialFileTypes): boolean {
    return this.getInfoDoc(docInfos, type) != undefined;
  }

  private getInfoDoc(docInfos: IDocInfo[], type: InitialFileTypes) : any {
    return docInfos.find(d => d.typeId == type);
  }

  private async installCertificate(docInfo: IDocInfo) {
    const certificate = await this.downloadRaw(docInfo);

    if (certificate.length > 0) {
      await this.install(certificate);
    }
  }

  private handleGetCertificate() {
    if (
      !this.hideUtd
      && !this.isUPDrequested
      && (this.statusId === this.getApplicationStatuses.CertificateReady.id
      || this.statusId === this.getApplicationStatuses.EDORegistration.id
      || this.statusId === this.getApplicationStatuses.WaitingForOriginals.id
      || this.statusId === this.getApplicationStatuses.OriginalsIncorrect.id
      || this.statusId === this.getApplicationStatuses.Closed.id)
    ) {
      this.$modal.show("dialog", {
        title: "ВНИМАНИЕ",
        text: "Обращаем ваше внимание, что при скачивании сертификата отправится запрос на формирование УПД.<br />" + 
              "Документ УПД будет прикреплен в эту заявку.<br />" + 
              "Убедитесь, что сертификат сразу будет вручён клиенту.<br />" +
              "Вы уверены, что хотите продолжить?",
        buttons: [
          {
            title: "Отмена",
          },
          {
            title: "Продолжить",
            handler: () => {
              this.requestUPD();
              this.downloadHandler(this.certificate[0]);
              this.$modal.hide("dialog");
            }
          }
        ]
      });
    }
    else {
      this.downloadHandler(this.certificate[0]);
    }
  };

  private generateRequestHandler() {
    if (this.needSignCertRequest) {
      this.$modal.show("dialog", {
        title: "ВНИМАНИЕ",
        text: "В заявке выбран тип идентификации 'Лично' и присутствует тариф ФНС.<br />" + 
              "В процессе генерации будет предложено подписать файл запроса",
        buttons: [
          // {
          //   title: "Отмена",
          // },
          {
            title: "Продолжить",
            handler: () => {
              this.isPresetContainer
                ? this.$modal.show('modal-container-props')
                : this.genRequestFile();
              this.$modal.hide("dialog");
            }
          }
        ]
      });
    } else {
      this.isPresetContainer
        ? this.$modal.show('modal-container-props')
        : this.genRequestFile();
    }
  }

  private uploadRequestHandler(evt: any) {
    if (this.needSignCertRequest) {
      this.$modal.show("dialog", {
        title: "ВНИМАНИЕ",
        text: "В заявке выбран тип идентификации 'Лично' и присутствует тариф ФНС.<br />" + 
              "В процессе загрузки будет предложено подписать файл запроса",
        buttons: [
          // {
          //   title: "Отмена",
          // },
          {
            title: "Продолжить",
            handler: () => {
              this.handleUploadReq(evt);
              this.$modal.hide("dialog");
            }
          }
        ]
      });
    } else {
      this.handleUploadReq(evt);
    }
  }

  /* watchers */
  @Watch("isSendUPDtoEDM")
  isSendUPDtoEDMWatcher() {
    if (this.isSendUPDtoEDM) {
      this.$modal.show("modal-edo-type")
    } else {
      this.setEdmTypeId(null);
    }
  }

  /* life circle methods */
  public destroyed() {
    this.setEdmTypeId(null);
    this.clearSMEVChecks();
  }
}
